import { Check } from "@sparkware/app-utils";
import {
  AppIdentifiers,
  ChatOpenMode,
  TriggerType,
  AreaType,
  OpeningMode,
  ActionID,
} from "@sparkware/uc-sdk-core";
import React, {
  FC,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Brand } from "../../services/models/brand";
import {
  ChatContext,
  useChatContext,
} from "../../services/contexts/chat-context";
import {
  CHAT_APP_ID,
  ORACLE_SDK_SCRIPT,
  CHAT_CONTAINER,
  LOTTIE_PLAYER_SCRIPT,
} from "../../types/global";
import { loadScriptTag } from "../../utils/utility";
import {
  ChatUrgentMessage,
  ChatUrgentMessageTextContainer,
  ChatUrgentMessageText,
  ChatUrgentMessageAlert,
  ChatUrgentMessageAlertImg,
  ChatFooterMessage,
  ChatFooterMessageTextContainer,
  ChatFooterMessageText,
  ChatFooterMessageAlertImg,
  ChatFooterMessageAlert,
  ChatFooterContainerText,
  ChatFooterText, //ChatFooterLogo, ChatFooterLogoContainer, ChatUrgentMessageLogo
} from "./common.component";
import { settings } from "cluster";
import { v4 as uuidv4 } from "uuid";
import { IOracleChat } from "../../services/chat-data/chat-state.service.interface";
import { ServiceFactory } from "../../services/service-factory";
import useResizeObserver from "@react-hook/resize-observer";
import { UrgentMessagePopup } from "../help/urgent-message-popup.component";
import {
  BrandDictionary,
  SubbrandDictionary,
} from "../../enums/brand-dictionary";

declare enum ChatStatus {
  Connecting = 0,
  Open = 1,
  Closing = 2,
  Closed = 3,
}

declare var WebSDK: IOracleChat;

const ChatWindow = styled.div`
  width: 100%;
  height: 100%;
`;

const ChatTitle = styled.div`
  color: ${(props: any) => props.theme.colors.secondaryText};
  letter-spacing: 0.98px;
  font-family: ${(props: any) => props.theme.fontFamily} !important;
  height: 50px;
  line-height: 50px;
  z-index: 9999;
`;

const ChatFooterButton: FC<any> = (props: any) => {
  const sendMessage = (message: string) => {
    /// window.Bots && window.Bots.sendMessage(message, { hidden: true });
  };

  return (
    <button
      onClick={() => sendMessage(props.message)}
      className={props.className}
    >
      {props.children}
    </button>
  );
};

const Styled = styled(ChatFooterButton)`
  background-color: transparent;
  border: 2px solid ${(props: any) => props.theme.colors.primary};
  color: ${(props: any) => props.theme.colors.primary};
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 8px;
  -webkit-letter-spacing: 0.98px;
  -moz-letter-spacing: 0.98px;
  -ms-letter-spacing: 0.98px;
  letter-spacing: 0.98px;
  padding: 4px 12px;
  cursor: pointer;
  margin-right: 10px;
`;

const ChatFooter: FC<any> = (props: any) => {
  return (
    <ChatFooterContainerText
      id="footer_text"
      text={props.text}
      logoSource={props.logoSource}
    >
      {/* <ChatUrgentMessageAlert className="alert"><ChatUrgentMessageAlertImg src={props.logoSource} alt="responsible gaming logo"></ChatUrgentMessageAlertImg></ChatUrgentMessageAlert> */}
      {/* <ChatFooterLogoContainer>
                <ChatFooterLogo src={props.logoSource} alt="responsible gaming logo" onClick={props.onClick}></ChatFooterLogo>
            </ChatFooterLogoContainer>  */}
      <ChatFooterText
        dangerouslySetInnerHTML={{ __html: props.text ? props.text : "" }}
      ></ChatFooterText>
    </ChatFooterContainerText>
  );
};

export const OracleChat: FC<any> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  // const [bot, setBot] = useState<any>(null);

  const services = useChatContext().services;
  const containerRef = useRef(null);

  const chatSettings = services.chatData.chatSettings;
  const chatHours = services.chatData.chatHours;
  const playerData = services.chatData.playerData;
  const chatInitParameters = services.chatData.chatInitParameters;
  const themes = services.themes;
  const languageService = services.languageService;

  const OnlineBankingUrl = `https://connect.consents.online/888/signup/selectbank/`;

  useEffect(() => {
    if (!window.customElements.get("lottie-player")) {
      loadScriptTag(
        LOTTIE_PLAYER_SCRIPT,
        services.resourceService.resolveResourcePath("/lottie-player.js")
      )
        .then(() => {
          console.log("lottie player script loaded");
        })
        .catch(() => {
          console.error("Lottie player load failed.");
        });
    }

    loadScriptTag(
      ORACLE_SDK_SCRIPT,
      services.resourceService.resolveResourcePath("/web-sdk.21.12.js")
    )
      .then(() => {
        initOracleSdk();
      })
      .catch(() => {
        services.messageBroker.initFailed(CHAT_APP_ID, {
          errorCode: 1,
          errorDescription: "Oracle sdk load failed.",
        });
      });
  }, []);

  const channelExtensionsDelegate = {
    beforeDisplay: function (message: any) {
      // Set cards for Agent Feedback
      if (message.messagePayload.cards && message.messagePayload.cards) {
        message.messagePayload.cards = message.messagePayload.cards.map(
          (card: any) => {
            if (card.actions) {
              card.actions = card.actions.map((action: any) => {
                if (
                  action.channelExtensions &&
                  action.channelExtensions.payload
                ) {
                  action.postback = action.channelExtensions.payload;
                  action.postback.variables = action.postback.variables || {};
                }
                return action;
              });
            }
            return card;
          }
        );
      }

      //transfer to agent animation
      /* This prefix identifies that we're waiting to transfer to an agent */
      if (
        message.messagePayload.text &&
        message.messagePayload.text.startsWith("AGENT_TRANSFER_START")
      ) {
        message.messagePayload.text = message.messagePayload.text.replace(
          "AGENT_TRANSFER_START ",
          ""
        );

        let lottieSrc = themes.currentTheme.assets.images.transfer_to_agent;

        document
          .getElementsByClassName("oda-chat-footer")[0]
          .insertAdjacentHTML(
            "beforebegin",
            '<div id="transfer-animation-container"><lottie-player id="transfer-animation" src="' +
              lottieSrc +
              '"  background="transparent"  speed="1"  autoplay loop></lottie-player></div>'
          );

        /* Setting a timeout means the element is rendered and loaded
         * before displaying, which makes the animation smoother */
        setTimeout(function () {
          document
            .querySelector("#transfer-animation-container")
            ?.classList.add("visible");
          console.log("lottie transfer added");
        }, 125);

        return message;
      }

      /* This prefix identifies that we've moved to a "post-agent-transfer" step */
      if (
        message.source === "AGENT" ||
        (message.messagePayload.text &&
          message.messagePayload.text.startsWith("AGENT_TRANSFER_END"))
      ) {
        message.messagePayload.text = message.messagePayload.text.replace(
          "AGENT_TRANSFER_END ",
          ""
        );

        let animContainer = document.getElementById(
          "transfer-animation-container"
        );
        animContainer?.classList.remove("visible");
        console.log("lottie transfer removed");

        setTimeout(function () {
          animContainer?.remove();
        }, 1500);

        return message;
      }

      /* Display agent feedback survey */
      if (message && message.messagePayload.actions) {
        message.messagePayload.actions.forEach((action: any) => {
          if (
            action.postback.variables.webviewUrl &&
            action.postback.variables.webviewLinkId
          ) {
            message.messagePayload.text +=
              `<a href="${action.postback.variables.webviewUrl}" ` +
              `id="${action.postback.variables.webviewLinkId}" target="oda-chat-webview"/>`;
          }
        });
      }

      return message;
    },
    beforeSend: function (message: any) {
      return message;
    },
    beforePostbackSend: function (postback: any) {
      if (postback?.messagePayload?.postback?.variables?.actionName) {
        performActionSendEvent(
          postback.messagePayload.postback.variables.actionName
        );
      }

      let webviewLinkId =
        postback.messagePayload.postback.variables.webviewLinkId;
      let webviewLink = document.getElementById(webviewLinkId);
      if (webviewLink) {
        webviewLink.click();
        webviewLink.remove();
      }

      return postback;
    },
  };

  const performActionSendEvent = (action: string) => {
    let seqCorrelationId = services.chatData?.analyticsEventData
      .sequentialCorrelationID
      ? services.chatData?.analyticsEventData.sequentialCorrelationID
      : 1;
    seqCorrelationId++;
    if (action == "OPEN_BANKING") {
      services.chatData.analyticsEventData.userChooseOpenBanking = true;

      let url = `${OnlineBankingUrl}?externalReference=${playerData?.cid}`;

      setTimeout(() => {
        let launchInfo = {
          channel: {
            area: AreaType.button,
            source: AppIdentifiers.CrChat,
            element: "open_banking",
          },
          businessCorrelationID:
            services.chatData.chatInitParameters?.applicationContext.launchInfo
              .businessCorrelationID!,
          trigger: TriggerType.userSelection,
          appSpecificParameters: { url: url },
          sourceAppID: AppIdentifiers.CrChat,
          sourceAppVersion: process.env.REACT_APP_VERSION!,
          openingMode: OpeningMode.popup,
          sequentialCorrelationID: seqCorrelationId,
        };

        services.logger.info("Perform Action ", launchInfo);

        services.messageBroker.performAction(
          AppIdentifiers.CrChat,
          ActionID.openBanking,
          launchInfo
        );
      }, 3000);
    }

    if (action == "UPLOAD_DOCUMENTS") {
      let launchInfo = {
        channel: {
          area: AreaType.button,
          source: AppIdentifiers.CrChat,
          element: "upload_documents",
        },
        businessCorrelationID:
          services.chatData.chatInitParameters?.applicationContext.launchInfo
            .businessCorrelationID!,
        trigger: TriggerType.userSelection,
        appSpecificParameters: {},
        sourceAppID: AppIdentifiers.CrChat,
        sourceAppVersion: process.env.REACT_APP_VERSION!,
        openingMode: OpeningMode.popup,
        sequentialCorrelationID: seqCorrelationId,
      };

      setTimeout(() => {
        services.logger.info("Perform Action ", launchInfo);

        services.messageBroker.performAction(
          AppIdentifiers.CrChat,
          ActionID.uploadDocuments,
          launchInfo
        );
      }, 3000);
    }

    if (services.chatData) {
      services.chatData.analyticsEventData.sequentialCorrelationID =
        seqCorrelationId;
    }

    if (action == "LIVE_AGENT") {
      services.chatData.analyticsEventData.userRequestAgent = true;
    }
  };

  const brand = new Brand(
    chatInitParameters?.applicationContext.brandID,
    chatSettings?.brandName
  );
  const { launchInfo } =
    services.applicationService?.appInitParameters?.applicationContext!;

  let profileProperties = {
    accType: chatSettings?.channelType,
    lang: chatSettings?.rnLanguageId,
    cid: playerData?.cid?.toString(),
    sportCat: "",
    locale: chatSettings?.locale,
    chatRefID: "1",
    verifiedplayer: "1",
    playerValue: playerData?.playerValue,
    agentChannel: chatSettings?.agentChannel,
    onlyAgentFlow: chatSettings?.onlyAgentFlow,
    brand: chatSettings?.rnBrandId,
    isGoodWillBonusEnabled: chatSettings?.isGoodWillBonusEnabled,
    goodWillBonusUrl: services.chatData.environmentSettings.apiUrl,
    correlationId: launchInfo?.businessCorrelationID ?? "-1",
    sequentialCorrelationId: launchInfo?.sequentialCorrelationID ?? -1,
    brandId: chatInitParameters?.applicationContext.brandID,
    subBrandId: chatInitParameters?.applicationContext.subBrandID,
    langId: chatInitParameters?.applicationContext.languageCode,
    playerValueId: playerData?.playerValueId,
    clientTypeId: playerData?.clientTypeId,
    timezone: chatSettings?.timezone,
    brandName: chatSettings?.brandName,
    brandDisplayName: brand.displayName,
    imagesBaseUrl: services.resourceService.resolveResourcePath("/images/"),
    interface: chatSettings?.interface,
    interfaceRoute: chatSettings?.interfaceRoute,
    FAQreportId: chatSettings?.FAQreportId,
    osvcBrandId: chatSettings?.osvcBrandId,
    osvcSubBrandId: chatSettings?.osvcSubBrandId,
    osvcAnswerLanguageId: chatSettings?.osvcAnswerLanguageId,
    engagementIDAuthenticated: chatSettings?.engagementIDAuthenticated,
    engagementIDUnauthenticated: chatSettings?.engagementIDUnauthenticated,
    htmlEngagementIDAuthenticated: chatSettings?.htmlEngagementIDAuthenticated,
    htmlEngagementIDUnauthenticated: chatSettings?.htmlEngagementIDUnauthenticated,
    htmlEngagementIDProactive: chatSettings?.htmlEngagementIDProactive,
    showChatButtonAuthenticated: chatSettings?.showChatButtonAuthenticated,
    showChatButtonUnauthenticated: chatSettings?.showChatButtonUnauthenticated,
    ...services.chatData.chatSettings?.dynamicSettings,
  };

  const cid = playerData?.cid?.toString() ? playerData?.cid?.toString() : "";
  let userId = window.localStorage.getItem(cid);
  if (!userId) {
    userId = uuidv4();
    window.localStorage.setItem(cid, userId);
  }

  // let chatbotIcons = {
  //     "botIcon": "/images/Amanda@2x.png"
  // };
  // switch (chatInitParameters?.applicationContext.brandID) {
  //     case 0: chatbotIcons = {
  //                 "botIcon": "/images/Amanda Casino Bubble 55x55px@2x.png" }
  //             break;
  //     case 1: chatbotIcons = {
  //                 "botIcon": "/images/Amanda Poker Bubble 55x55px@2x.png"}
  //             break;
  //     case 8: chatbotIcons = {
  //                 "botIcon": "/images/Amanda Sport Bubble 55x55px@2x.png"}
  //             break;
  //     default: break;
  // };
  console.log(themes.currentTheme.colors.primary);
  const initSdkSettings = {
    //isDebugMode: true,
    URI: services.chatData.environmentSettings.odaUri,
    channelId:
      services.chatData.chatOpenMode == ChatOpenMode.SOF
        ? chatSettings?.sofBotAppId
        : chatSettings?.botAppId,
    //userId: userId,
    userId: `${playerData?.cid}_${new Date().getMilliseconds()}`,
    //userId: `${playerData?.cid}`,
    enableEndConversation: false,
    embedded: true,
    targetElement: "chatbot_window",
    enableAttachment: false,
    enableTimestamp: true,
    timestampFormat: "HH:mm",
    storageType: "sessionStorage",
    i18n: {
      en: {
        chatTitle: "",
        // "inputPlaceholder": chatSettings?.inputPlaceholder
        inputPlaceholder: languageService.translate("Input_PlaceHolder"),
      },
    },
    customHeaderElementId: "chat_header",
    embedTopScrollId: "urgent_message",
    embedBottomStickyId: "footer_text",
    enableAutocomplete: services.chatData.chatOpenMode == ChatOpenMode.SOF,
    enableAutocompleteClientCache:
      services.chatData.chatOpenMode == ChatOpenMode.SOF,
    logoIcon: "",
    botIcon: themes.currentTheme.assets.images.avatar_help_center_2x_png,
    agentAvatar: themes.currentTheme.assets.images.avatar_help_center_2x_png,
    sendIcon:
      '<svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18"><defs><style>.a{fill:' +
      themes.currentTheme.colors.primary +
      ';}</style></defs><path class="a" d="M1.176,5.75V-1.234l15-2.016-15-2.016V-12.25l21,9Z" transform="translate(-1.176 12.25)"/></svg>',
    chatBubbleIcon: themes.currentTheme.assets.images.typing_animation_gif,
    chatBubbleIconHeight: "28px",
    chatBubbleIconWidth: "64px",
    colors: {
      headerBackground: themes.currentTheme.colors.primaryHeader,
      userMessageBackground: themes.currentTheme.colors.userMessageBackground,
      userText: themes.currentTheme.colors.messageBackground,
      botMessageBackground: themes.currentTheme.colors.messageBackground,
      botText: themes.currentTheme.colors.secondary,
      actionsBackground: "rgb(" + themes.currentTheme.colors.primary + ")",
      actionsBorder: "rgb(" + themes.currentTheme.colors.primary + ")",
      actionsBackgroundHover:
        "rgba(" + themes.currentTheme.colors.primary + ", 0.8)",
      actionsBackgroundFocus:
        "rgba(" + themes.currentTheme.colors.primary + ", 0.8)",
      conversationBackground: themes.currentTheme.colors.mainWindowBackground,
      footerBackground: themes.currentTheme.colors.messageBackground,
      inputBackground: themes.currentTheme.colors.messageBackground,
      inputText: themes.currentTheme.colors.secondary,
      footerButtonFill: "rgb(" + themes.currentTheme.colors.primary + ")",
      ratingStarFill: themes.currentTheme.colors.primary,
    },
    initUserHiddenMessage:
      services.chatData.chatOpenMode == ChatOpenMode.Chat &&
      chatSettings?.onlyAgentFlow === "true"
        ? "live agent from agent"
        : chatSettings?.initUserHiddenMessage,
    initUserProfile: {
      profile: {
        firstName: playerData?.firstName,
        lastName: playerData?.lastName,
        email: playerData?.email,
        locale: chatSettings?.locale,
        countryISO3: playerData?.countryISO3,
        countryId: playerData?.countryId,
        registrationCountry: playerData?.registrationCountry,
        isUk: playerData?.isUk,
        //other custom values you may want to pass
        properties: profileProperties,
      },
    },
    webViewConfig: {
      title: languageService.translate("Survey_Title"),
      // size: "full",
      errorInfoBar: false,
    },
  };

  // const handleResize = (chatWindow: any) =>{
  //     //let chatWindow = _chatWindow as HTMLElement;
  //     // window.Bots && window.Bots.setSize(chatWindow[0]?.target.offsetWidth + 'px' ,chatWindow[0]?.target.offsetHeight + 'px');
  //     if(chatWindow){
  //         services
  //           .chatService
  //           .setChatWindowSize(chatWindow.offsetWidth,
  //                             chatWindow.offsetHeight);
  //         console.log('resized to: ', chatWindow[0]?.target.offsetWidth, 'x', chatWindow[0]?.target.offsetHeight)
  //     }

  // }

  const initOracleSdk = () => {
    // Default name is Bots
    let chatWindow = document.getElementById("chatbot_window1");
    setTimeout(() => {
      try {
        const currentBot = new WebSDK({
          ...initSdkSettings,
          openChatOnLoad: true,
        });
        services.chatService.setActiveChat(currentBot);
        if (Check.isNullOrUndefined(currentBot)) {
          // console.log(`connected: ${window.Bots.isConnected()}`);
          // console.log(`chat Opened: ${window.Bots.isChatOpened()}`);
          //window.Bots.disconnect();
          //window.Bots.closeChat();
        }
        // let isHandled = false;

        currentBot.on(
          "message:received",
          function (message: {
            messagePayload: {
              text: string;
              msgId: string;
              source: string;
              userId: string;
              channelExtensions: any;
            };
            count: number;
          }) {
            services.messageBroker.chatActivity(CHAT_APP_ID, message.count);
            if (message.messagePayload.text === "********") {
              currentBot.disconnect();
            }

            //Confetti
            if (
              message &&
              message.messagePayload.channelExtensions &&
              message.messagePayload.channelExtensions.ShowConfetti
            ) {
              let lottieSrc = `${services.resourceService.resolveResourcePath(
                "/animations"
              )}/bonus_confetti.json`;

              document
                .querySelector(".oda-chat-conversation")
                ?.insertAdjacentHTML(
                  "beforebegin",
                  '<lottie-player id="bonus-confetti" src="' +
                    lottieSrc +
                    '"  background="transparent"  speed="1" autoplay></lottie-player>'
                );
              console.log("added lottie player confetti");

              const player = document.querySelector("#bonus-confetti");
              player?.addEventListener("complete", () => {
                player.remove();
                console.log("removed lottie player confetti");
              });
            }
            //Confetti
          }
        );
        //currentBot.setSize("100%", "100%")

        currentBot.on("message:sent", function (message: string) {
          services.chatData.analyticsEventData.userSentMessage = true;
        });
        // let timerStart = new Date().getTime();
        let isFirstConnection = true;

        let timerStart = new Date().getTime();
        currentBot
          .connect() // Connect to server
          .then(
            () => {
              currentBot.setDelegate(channelExtensionsDelegate);

              let timerEnd = new Date().getTime();
              let timeElapsed = (timerEnd - timerStart) / 1000 + " seconds";
              services.messageBroker.initSucceded(CHAT_APP_ID);

              // const resizeObserver = new ResizeObserver(handleResize);
              // resizeObserver.observe(chatWindow as Element);
              // services.messageBroker.resizeObservers = resizeObserver;

              console.log(`Bot load time elapsed: ${timeElapsed}`);
              setLoading(false);

              services.logger.info("Bot created", initSdkSettings);
            },
            (reason: String) => {
              treatChatLoadError(reason, timerStart);
            }
          )
          .catch((reason: String) => {
            treatChatLoadError(reason, timerStart);
          });
      } catch (ex) {
        services.messageBroker.initFailed(CHAT_APP_ID, {
          errorCode: 1,
          errorDescription: "Oracle chatbot error.",
        });
      }
    }, 0);
  };

  const treatChatLoadError = (reason: String, timerStart: number) => {
    let timerEnd = new Date().getTime();
    let timeElapsed = (timerEnd - timerStart) / 1000 + " seconds";
    services.messageBroker.initFailed(CHAT_APP_ID, {
      errorCode: 1,
      errorDescription: reason.trim(),
    });
    services.logger.error("Bot created error @reason", reason);
    console.log(`Bot load time elapsed with error: ${timeElapsed}`);
  };

  useResizeObserver(containerRef, ({ contentRect }: ResizeObserverEntry) => {
    console.log(`Resize observer called: ${JSON.stringify(contentRect)}`);

    services.chatService.setChatWindowSize(
      contentRect.width,
      contentRect.height
    );
  });

  const [isUrgentMessageTextExpanded, setIsUrgentMessageTextExpanded] =
    useState(false);

  const closeUrgentMessagePopup = () => {
    setIsUrgentMessageTextExpanded(false);
  };

  const onUrgentMessageClick = (isLargeText: boolean) => {
    if (isLargeText) {
      setIsUrgentMessageTextExpanded(true);
    }
  };

  let bannerText = chatHours?.urgentMessageText;
  let isLargeText = false;
  if (chatHours?.urgentMessageText) {
    const characterLimit = 80;

    isLargeText = chatHours?.urgentMessageText.length > characterLimit;

    if (isLargeText) {
      const lastCharacterPosition =
        chatHours?.urgentMessageText.indexOf(" ", characterLimit) === -1
          ? chatHours?.urgentMessageText.length
          : chatHours?.urgentMessageText.indexOf(" ", characterLimit);
      bannerText =
        chatHours?.urgentMessageText.substring(0, lastCharacterPosition) +
        "...";
    }
  }

  return (
    <div style={{ height: "100%", width: "100%" }} ref={containerRef}>
      {isUrgentMessageTextExpanded && (
        <UrgentMessagePopup
          onClose={closeUrgentMessagePopup}
        ></UrgentMessagePopup>
      )}
      <div
        style={{
          zIndex: 100,
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "#293134",
          display: loading ? "block" : "none",
        }}
      >
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {/* Live Chat title is hardcoded for SOF for now until we can implement a more complex themeing or cms mechanism  */}
      <ChatTitle id="chat_header">
        {languageService.translate("Chat_Title")}
      </ChatTitle>
      {/* <ChatFooter text={chatSettings?.footertext} onClick={() => window.open(chatSettings?.rgLink, '_blank')} logoSource={services.resourceService.resolveResourcePath('/images/RG_logo.png')} isSof={true} ></ChatFooter> */}
      <ChatUrgentMessage
        id="urgent_message"
        text={bannerText}
        isLargeText={isLargeText}
        onClick={() => onUrgentMessageClick(isLargeText)}
      >
        <ChatUrgentMessageTextContainer>
          <ChatUrgentMessageText
            dangerouslySetInnerHTML={{ __html: bannerText ? bannerText : "" }}
          ></ChatUrgentMessageText>
        </ChatUrgentMessageTextContainer>
        <ChatUrgentMessageAlert className="alert">
          <ChatUrgentMessageAlertImg
            src={
              services.themes.currentTheme.assets.images.urgent_message_icon_svg
            }
            alt="alert icon"
          ></ChatUrgentMessageAlertImg>
        </ChatUrgentMessageAlert>
      </ChatUrgentMessage>
      <ChatFooterMessage id="footer_text" text={chatSettings?.footertext}>
        <ChatFooterMessageTextContainer>
          <ChatFooterMessageAlert
            id="chatFooterMessageAlert"
            link={chatSettings?.rgLink}
            className=""
          >
            <ChatFooterMessageAlertImg
              src={services.resourceService.resolveResourcePath(
                "/images/RG_logo.png"
              )}
              alt="responsible gaming"
              textLength={chatSettings?.footertext?.length}
            ></ChatFooterMessageAlertImg>
          </ChatFooterMessageAlert>

          <ChatFooterMessageText
            id="chatFooterMessageText"
            link={chatSettings?.rgLink}
            dangerouslySetInnerHTML={{
              __html: chatSettings?.footertext ? chatSettings.footertext : "",
            }}
          ></ChatFooterMessageText>
        </ChatFooterMessageTextContainer>
      </ChatFooterMessage>

      <ChatWindow id={CHAT_CONTAINER}></ChatWindow>
    </div>
  );
};
