import { Check } from "@sparkware/app-utils";
import {
  IAppClosedTopicPayload,
  IAppInitFailedTopicPayload,
  IAppInitParameters,
  IAppLaunchInfo,
  IMessageBroker,
} from "@sparkware/uc-sdk-core";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { ChatChannel } from "../types/chat-models";
import Axios from "../utils/AxiosInstance";
import { isNotSet } from "../utils/utility";
import { ChatSettings } from "./chat-data/chat-data.service.interface";
import { CRChannel } from "./help-widget/help-data.service";
import { ServiceBase } from "./service-base";
import { IServiceBase } from "./service-base.interface";
import { IServiceFactory } from "./service-factory.interface";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_LANGUAGE } from "./language/language.service";

export type AppSettings = ChatSettings;
export type AppClosedFn = (payload: IAppClosedTopicPayload) => void;

export interface IApplicationService extends IServiceBase {
  appInitParameters?: IAppInitParameters;
  settings?: AppSettings;

  isLoading: boolean;
  correlationId: string;
  sequentialCorrelationID: number;

  init: (inputData: IAppInitParameters) => void;

  loadSettings: (channel: CRChannel) => Promise<void>;

  appClosedCallback?: AppClosedFn;
}

export class ApplicationService
  extends ServiceBase
  implements IApplicationService
{
  constructor(services: IServiceFactory) {
    super(services);
    makeObservable(this, {
      appInitParameters: observable,
      _isLoading: observable.ref,
      settings: observable,

      init: action.bound,
      close: action.bound,
      loadSettings: action.bound,

      correlationId: computed,
    });
  }

  appClosedCallback?: AppClosedFn | undefined;
  appInitParameters?: IAppInitParameters<IAppLaunchInfo<any>> | undefined;
  _isLoading: boolean = true;
  settings?: ChatSettings;

  close() {
    if (this.services) {
      this.services.dispose(); // throws error: on topic appClosed in channel session failed Error: Method not implemented.
    }
  }

  init(inputData: IAppInitParameters) {
    const { messageBroker, languageService } = this.services;

    if (Check.isNullOrUndefined(inputData)) {
      this.isLoading = false;
      throw new Error("Invalid init parameters");
    }

    this.services.logger.info("Application service - Load init data", {
      applicationContext: inputData.applicationContext,
      deviceInfo: inputData.deviceInfo,
      geoInfo: inputData.geoInfo,
      userInfo: inputData.userInfo,
    });
    // try not to load twice
    if (!Check.isNullOrUndefined(this.appInitParameters)) {
      return;
    }

    this.appInitParameters = inputData;
    languageService.setCurrentLanguage(
      inputData.applicationContext.locale ?? DEFAULT_LANGUAGE
    );

    //subscribe to general events
    messageBroker.subscribeTo(
      (channels: IMessageBroker) => {
        return channels.session.topics.appClosed;
      },
      (payload: IAppClosedTopicPayload) => {
        if (this.appClosedCallback == undefined) {
          return;
        }
        this.appClosedCallback!(payload);
      }
    );

    messageBroker.subscribeTo(
      (channels: IMessageBroker) => {
        return channels.session.topics.appInitFailed;
      },
      (payload: IAppInitFailedTopicPayload) => {
        this.logger.error("Application initialization failed");
      }
    );
  }

  get correlationId(): string {
    return this.appInitParameters?.correlationID ?? `Widget_${uuidv4()}`;
  }

  get sequentialCorrelationID(): number {
    return (
      this.appInitParameters?.applicationContext.launchInfo
        .sequentialCorrelationID ?? 1
    );
  }
  set sequentialCorrelationID(value: number) {
    const launchInfo = this.appInitParameters?.applicationContext.launchInfo;
    if (!launchInfo) return;
    runInAction(() => {
      launchInfo.sequentialCorrelationID = value;
    });
  }

  set isLoading(loadingState: boolean) {
    runInAction(() => {
      this._isLoading = loadingState;
    });
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  async loadSettings(channel: CRChannel): Promise<void> {
    this.settings = await this._loadSettings(channel);
  }

  private async _loadSettings(channel: CRChannel): Promise<ChatSettings> {
    this.services.logger.info("Load settings start", channel);

    const response = await Axios(this.services)
      .get(this.environmentSettings.apiUrl + "settings/chat?", {
        params: channel,
      })
      .catch((e: any) => {
        //this.throwInitFailedMesage({errorCode: "1", errorDescription: 'Could not load chat settings'});
        // this.services.logger.error("Load settings error", e);
        throw new Error("Could not load chat settings");
      });

    this.services.logger.info("Load settings response", response);
    const settings = response.data.settings;

    const botSettings = Object.entries<string>(settings)
      .filter(([key]) => key.startsWith("$"))
      .reduce((prev, [key, value]) => {
        prev[key] = value;
        return prev;
      }, <{ [key: string]: string }>{});

    console.log(response);
    return {
      chatHoursEntry: response.data.settings.chatHoursEntry,
      brandColor: response.data.settings.brandColor,
      conversationColor: response.data.settings.conversationColor,
      actionColor: response.data.settings.actionColor,
      pageTitle: response.data.settings.pageTitle,
      closedTitle: response.data.settings.closedTitle,
      closed: response.data.settings.closed,
      botAppId: response.data.botId,
      sofBotAppId: response.data.sofBotId,
      headerText: response.data.settings.headerText,
      channelType: response.data.settings.channelType,
      channelLang: response.data.settings.channelLang,
      locale: response.data.settings.locale,
      timezone: response.data.settings.timezone,
      agentChannel: response.data.agentChannelName,
      onlyAgentFlow: response.data.settings.onlyAgentFlow,
      inputPlaceholder: response.data.settings.inputPlaceholder,
      rnBrandId: response.data.settings.rnBrandId,
      rnLanguageId: response.data.settings.rnLanguageId,
      isGoodWillBonusEnabled: response.data.settings.isGoodWillBonusEnabled,
      footertext: response.data.settings.footertext,
      rgLink: response.data.settings.rgLink,
      sendButtonIcon: response.data.settings.sendButtonIcon,
      themeName: response.data.settings.Theme,
      brandName: response.data.settings.brandName,
      interface: response.data.settings.interface,
      interfaceRoute: response.data.settings.interfaceRoute,
      FAQreportId: response.data.settings.FAQreportId,
      osvcBrandId: response.data.settings.osvcBrandId,
      osvcSubBrandId: response.data.settings.osvcSubBrandId,
      osvcAnswerLanguageId: response.data.settings.osvcAnswerLanguageId,
      phoneHours: response.data.settings.phoneHours,
      initUserHiddenMessage: response.data.settings.initUserHiddenMessage,
      showEmailButton: response.data.settings.showEmailButton,
      showAttachmentButton: response.data.settings.showAttachmentButton,
      use24hTimeFormat: response.data.esttings.use24hTimeFormat,
      enableLPchat: response.data.settings.enableLPchat,
      engagementIDAuthenticated: response.data.settings.EngagementID_Auth,
      engagementIDUnauthenticated: response.data.settings.EngagementID_NoAuth,
      engagementIDProactive: response.data.settings.EngagementID_Proactive,
      htmlEngagementIDAuthenticated: response.data.settings.htmlEngagementID_Auth,
      htmlEngagementIDUnauthenticated: response.data.settings.htmlEngagementID_NoAuth,
      htmlEngagementIDProactive: response.data.settings.htmlEngagementID_Proactive,
      showChatButtonAuthenticated: response.data.settings.showChatButton_Auth,
      showChatButtonUnauthenticated: response.data.settings.showChatButton_NoAuth,
      dynamicSettings: botSettings,
    };
  }
}
