import { IAssetResolver } from "../../architecture/asset-resolver/asset-resolver.interface";
import { ImageEnumType } from "../../architecture/asset-resolver/make-assets/image-enum.type";
import { makeAsset } from "../../architecture/asset-resolver/make-assets/make-assets";
import { IWidgetTheme } from "../../architecture/widget-theme/interface/widget-theme.interface";
import { makeWidgetTheme } from "../../architecture/widget-theme/make-widget-theme";
import { seven77Theme } from "./seven77-theme";

export function make777Theme(assetResolver: IAssetResolver): IWidgetTheme {
        return makeWidgetTheme(
        assetResolver,
        seven77Theme.colors,
        {
            images:{
                avatar_help_center_2x_png: makeAsset('images/Avatar Help Center.png', assetResolver),
                send_button_png: makeAsset(`images/777 Dublin/${seven77Theme.images.sendButton}.png`, assetResolver),
                send_button_2x_png: makeAsset(`images/777 Dublin/${seven77Theme.images.sendButton}@2x.png`, assetResolver),
                live_chat_icon_png: makeAsset(`images/777 Dublin/${seven77Theme.images.liveChatIcon}.png`, assetResolver),
                live_chat_icon_2x_png: makeAsset(`images/777 Dublin/${seven77Theme.images.liveChatIcon}@2x.png`, assetResolver),

                minimize_icon_png: makeAsset(`images/777 Dublin/${seven77Theme.images.amandaBubble}.png`, assetResolver),
                minimize_icon_2x_png: makeAsset(`images/777 Dublin/${seven77Theme.images.amandaBubble}@2x.png`, assetResolver),
                minimized_new_message_icon_png: makeAsset(`images/777 Dublin/${seven77Theme.images.amandaBubbleWithNotification}.png`, assetResolver), 
                minimized_new_message_icon_2x_png: makeAsset(`images/777 Dublin/${seven77Theme.images.amandaBubbleWithNotification}@2x.png`, assetResolver),   
               
                infoIcon : makeAsset(`/images/777 Dublin/${seven77Theme.images.infoIcon}.svg`, assetResolver),
                infoIconHover : makeAsset(`/images/777 Dublin/${seven77Theme.images.infoIconHover}.svg`, assetResolver),

                amanda_help_sleeping_png: makeAsset(`images/777 Dublin/${seven77Theme.images.amandaSleeping}.png`, assetResolver),
                amanda_help_sleeping_2x_png: makeAsset(`images/777 Dublin/${seven77Theme.images.amandaSleeping}@2x.png`, assetResolver),

                typing_animation_gif: makeAsset(`images/777 Dublin/${seven77Theme.images.typingAnimation}.gif`, assetResolver),

                close_button_png: makeAsset(`images/777 Dublin/${seven77Theme.images.closeButton}.png`, assetResolver),
                close_button_2x_png: makeAsset(`images/777 Dublin/${seven77Theme.images.closeButton}@2x.png`, assetResolver),

                minimize_button_png: makeAsset(`images/777 Dublin/${seven77Theme.images.minimizeButton}.png`, assetResolver),
                minimize_button_2x_png: makeAsset(`images/777 Dublin/${seven77Theme.images.minimizeButton}@2x.png`, assetResolver),
                urgent_message_icon_svg: makeAsset(`images/777 Dublin/urgent-message-icon.svg`, assetResolver),

                transfer_to_agent: makeAsset(`animations/transfer_to_agent_777.json`, assetResolver)
                
            } as ImageEnumType,
        },
        seven77Theme.fontWeight,
        seven77Theme.textKeys, 
        seven77Theme.fontFamily
    )
}