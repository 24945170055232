import { isKeyof } from "./utils/utility";
import config from "./config.json";

export const env: string = "%ENVIRONMENT_NAME_PLACEHOLDER%";

export enum LogLevel {
  Debug = 0,
  Information,
  Error,
}

export function getConfig() {
  if (isKeyof(config, env)) {
    return {
      apiUrl: config[env].API_URL,
      odaUri: config[env].ODA_URI,
      clickstreamUrl: config[env].CLICKSTREAM_URL,
      resourcesOrigin: config[env].ResourcesOrigin,
      authorizationServiceApiUrl: config[env].jwtIssuerUrl,
      livePersonSiteId: config[env].livePersonSiteId
    };
  }
  if (process.env.REACT_APP_ENV) {
    if (isKeyof(config, process.env.REACT_APP_ENV)) {
      return {
        apiUrl: config[process.env.REACT_APP_ENV].API_URL,
        odaUri: config[process.env.REACT_APP_ENV].ODA_URI,
        clickstreamUrl: config[process.env.REACT_APP_ENV].CLICKSTREAM_URL,
        resourcesOrigin: config[process.env.REACT_APP_ENV].ResourcesOrigin,
        authorizationServiceApiUrl: config[process.env.REACT_APP_ENV].jwtIssuerUrl,
        livePersonSiteId: config[process.env.REACT_APP_ENV].livePersonSiteId
      };
    }
  }

  return {
    apiUrl: config.local.API_URL,
    odaUri: config.local.ODA_URI,
    clickstreamUrl: config.local.CLICKSTREAM_URL,
    resourcesOrigin: config.local.ResourcesOrigin,
    authorizationServiceApiUrl: config.local.jwtIssuerUrl,
    livePersonSiteId: config.local.livePersonSiteId,
  };
}

export function currenEnv(): string {
  return env;
}
