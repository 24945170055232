import { IAssetResolver } from "../../architecture/asset-resolver/asset-resolver.interface";
import { ImageEnumType } from "../../architecture/asset-resolver/make-assets/image-enum.type";
import { makeAsset } from "../../architecture/asset-resolver/make-assets/make-assets";
import { IWidgetTheme } from "../../architecture/widget-theme/interface/widget-theme.interface";
import { makeWidgetTheme } from "../../architecture/widget-theme/make-widget-theme";
import { mrgTheme } from "./mrg-theme";

export function makeMrgTheme(assetResolver: IAssetResolver): IWidgetTheme {
    return makeWidgetTheme(
        assetResolver,
        mrgTheme.colors,
        { 
            images:{
                avatar_help_center_2x_png: makeAsset('images/MrGreen/Avatar Help Center.png', assetResolver),
                send_button_png: makeAsset('images/Send Button.png', assetResolver),
                send_button_2x_png: makeAsset('images/Send Button@2x.png', assetResolver),
                live_chat_icon_png: makeAsset('images/MrGreen/Live Chat Icon Default.png', assetResolver),
                live_chat_icon_2x_png: makeAsset('images/MrGreen/Live Chat Icon Default@2x.png', assetResolver),

                minimize_icon_png: makeAsset(`images/MrGreen/${mrgTheme.images.amandaBubble}.png`, assetResolver),
                minimize_icon_2x_png: makeAsset(`images/MrGreen/${mrgTheme.images.amandaBubble}@2x.png`, assetResolver),
                minimized_new_message_icon_png: makeAsset(`images/MrGreen/${mrgTheme.images.amandaBubbleWithNotification}.png`, assetResolver), 
                minimized_new_message_icon_2x_png: makeAsset(`images/MrGreen/${mrgTheme.images.amandaBubbleWithNotification}@2x.png`, assetResolver),   
                infoIcon : makeAsset(`/images/${mrgTheme.images.infoIcon}.svg`, assetResolver),
                infoIconHover : makeAsset(`/images/${mrgTheme.images.infoIconHover}.svg`, assetResolver),

                amanda_help_sleeping_png: makeAsset('images/MrGreen/AmandaHelpSleeping@2x.png', assetResolver),
                amanda_help_sleeping_2x_png: makeAsset('images/MrGreen/AmandaHelpSleeping@2x.png', assetResolver),

                typing_animation_gif: makeAsset('images/Typing-Animation.gif', assetResolver),
                close_button_png: makeAsset('images/close-survey.svg', assetResolver),
                close_button_2x_png: makeAsset('images/X button@2x.png', assetResolver),

                minimize_button_png: makeAsset(`images/Minimize.png.png`, assetResolver),
                minimize_button_2x_png: makeAsset(`images/Minimize@2x.png`, assetResolver),

                urgent_message_icon_svg: makeAsset(`images/MrGreen/urgent-message-icon.svg`, assetResolver),

                transfer_to_agent: makeAsset(`animations/transfer_to_agent_default.json`, assetResolver)

            }  as ImageEnumType
        },
        mrgTheme.fontWeight,
        mrgTheme.textKeys,
        mrgTheme.fontFamily
    )
}

