import { IAssetResolver } from "../../architecture/asset-resolver/asset-resolver.interface";
import { makeAsset } from "../../architecture/asset-resolver/make-assets/make-assets";
import { IWidgetTheme } from "../../architecture/widget-theme/interface/widget-theme.interface";
import { makeWidgetTheme } from "../../architecture/widget-theme/make-widget-theme";
import { wsopMurphyTheme } from "./wsop-murphy-theme";
import { ImageEnumType } from "../../architecture/asset-resolver/make-assets/image-enum.type";
import { defaultTheme } from "../default/default-theme";

export function makeWsopMurphyTheme(assetResolver: IAssetResolver): IWidgetTheme {
    return makeWidgetTheme(assetResolver,
        wsopMurphyTheme.colors,
        {
            images:{
                avatar_help_center_2x_png: makeAsset('images/WSOP/Ava Avatar.png', assetResolver),
                send_button_png: makeAsset('images/Send Button.png', assetResolver),
                send_button_2x_png: makeAsset('images/Send Button@2x.png', assetResolver),
                live_chat_icon_png: makeAsset('images/WSOP/Live Chat Icon WSOP.png', assetResolver),
                live_chat_icon_2x_png: makeAsset('images/WSOP/Live Chat Icon WSOP@2x.png', assetResolver),
                minimize_icon_png: makeAsset(`images/WSOP/${wsopMurphyTheme.images.amandaBubble}.png`, assetResolver),
                minimize_icon_2x_png: makeAsset(`images/WSOP/${wsopMurphyTheme.images.amandaBubble}.png`, assetResolver),   
                minimized_new_message_icon_png: makeAsset(`images/WSOP/${wsopMurphyTheme.images.amandaBubbleWithNotification}.png`, assetResolver), 
                minimized_new_message_icon_2x_png: makeAsset(`images/WSOP/${wsopMurphyTheme.images.amandaBubbleWithNotification}.png`, assetResolver),
                amanda_help_sleeping_png: makeAsset('images/WSOP/Ava Sleeping.png', assetResolver),
                amanda_help_sleeping_2x_png: makeAsset('images/WSOP/Ava Sleeping.png', assetResolver),
                typing_animation_gif: makeAsset('images/Typing-Animation.gif', assetResolver),
                close_button_png: makeAsset('images/X button.png', assetResolver),
                close_button_2x_png: makeAsset('images/X button@2x.png', assetResolver),
                minimize_button_png: makeAsset(`images/Minimize.png.png`, assetResolver),
                minimize_button_2x_png: makeAsset(`images/Minimize@2x.png`, assetResolver),
                infoIcon: makeAsset(`images/WSOP/info-icon.svg`, assetResolver),
                infoIconHover: makeAsset(`images/WSOP/info-icon.svg`, assetResolver),

                // amanda_bubble_withNotification_png: makeAsset(`images/WSOP/Amanda WSOP Bubble Not. 55x55px.png`, assetResolver),
                // amanda_bubble_withNotification_2x_png: makeAsset(`images/WSOP/Amanda WSOP Bubble Not. 55x55px@2x.png`, assetResolver),   

                // chat_icon_svg: makeAsset(`images/chat.svg`, assetResolver),
                // chat_hover_icon_svg: makeAsset(`images/chat-default.svg`, assetResolver),
                // chat_disabled_icon_svg: makeAsset(`images/chat-disabled.svg`, assetResolver),

                // call_icon_svg: makeAsset(`images/call.svg`, assetResolver),
                // call_hover_icon_svg: makeAsset(`images/call-default.svg`, assetResolver),
                // call_disabled_icon_svg: makeAsset(`images/call-disabled.svg`, assetResolver),

                // faq_icon_svg: makeAsset(`images/faq.svg`, assetResolver),
                // faq_hover_icon_svg: makeAsset(`images/faq-default.svg`, assetResolver),
                // faq_disabled_icon_svg: makeAsset(`images/faq-disabled.svg`, assetResolver),

                // email_icon_svg: makeAsset(`images/email.svg`, assetResolver),
                // email_hover_icon_svg: makeAsset(`images/email-default.svg`, assetResolver),
                // email_disabled_icon_svg: makeAsset(`images/email-disabled.svg`, assetResolver),

                urgent_message_icon_svg: makeAsset(`images/urgent-message-icon.svg`, assetResolver),
                
                transfer_to_agent: makeAsset(`animations/transfer_to_agent_WSOP.json`, assetResolver)

            }  as ImageEnumType
        }, wsopMurphyTheme.fontWeight,
        wsopMurphyTheme.textKeys)
}


